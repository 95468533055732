<template>
    <vue-bottom-sheet ref="myBottomSheet" :max-height="'90%'" :max-width="'420px'" class="position-relative" :swipe-able="false">
    <div class="main-content">
        
        <div class="position-relative">
            <div class="appBar" >
                <a @click="close" style="top: 14px;"><i class="fa fa-chevron-left"></i></a>
                <h4 class="mx-4 text-center head_style2" style="font-weight: 600; padding: 4px;">{{ $t("enter_your_pin") }}</h4>
            </div>
        </div>
        <div class="mainpage pt-4">
            <b-row align-h="center">
                <b-col cols="12">
                    <b-form @submit.prevent="submitForm">
                        <div class="form-group row">
                            <div class="col-sm-12 mb-3">
                                <div class="input-group" style="padding-left: 50px;">
                                    <input v-for="(digit, index) in digits" :key="index" type="password"
                                        class="form-control label-input" :value="digit"
                                        @input="updateDigit(index, $event.target.value)" min="0" max="9" pattern="[0-9]"
                                        required maxlength="1" ref="inputFields" />
                                </div>
                            </div>
                            <label for="pin-input" class="text-14 mb-3">{{$t("enter_pin_proceed")}}</label>
                        </div>
                        <b-button @click="submitPin" class="mt-4 mb-5 btn-curved-wallet" variant="primary" block
                            :disabled="isLoading">{{ isLoading ? $t("loading...") : $t("confirm") }}</b-button>
                    </b-form>
                </b-col>
            </b-row>
        </div>
        <Dialog ref="msg"></Dialog>
   
    </div>
</vue-bottom-sheet>
</template>
  
<script>
import Dialog from "../../../components/dialog.vue";
import { mapGetters } from "vuex";
import VueBottomSheet from "@webzlodimir/vue-bottom-sheet";
export default {
    computed: {
        ...mapGetters(["lang"]),
    },
    components: {
        Dialog,
        VueBottomSheet
    },
    mounted() {
    this.$nextTick(() => {
        const panElement = document.querySelector('.bottom-sheet__pan');
        if (panElement) {
            panElement.style.display = 'none';
        }
        });
    },
    data() {
        return {
            isLoading: false,
            digits: ['', '', '', ''],
        };
    },
    props: ["success"],
    methods: {
        open() {
            this.$refs.myBottomSheet.open();
        },

        close() {
            this.$refs.myBottomSheet.close();
        },
        updateDigit(index, value) {
            // Update the digit at the specified index
            this.digits[index] = value;

            // Move focus to the next input field
            if (index < this.digits.length - 1) {
                this.$refs.inputFields[index + 1].focus();
            }
        },
        submitPin() {
            this.$emit('submit-pin', this.digits.join(''));
            this.$refs.myBottomSheet.close();
        },
    },
};
</script>
  
<style scoped>
.header {
  display: flex;
  /* padding: 0px 16px; */
  margin-top: 4rem !important;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
}

.main-content {
    max-width: 420px;
    margin: auto;
    height: 100vh;
}

.row{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.text-14{
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
}

</style>